import { Box, BoxProps } from "@chakra-ui/react";
import { FC } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import HeaderVideo from "./HeaderVideo";
import HeaderAnimatedImages from "./HeaderAnimatedImages";
import ElementAnimation from "src/components/elements/ElementAnimation";

export const NAVBAR_HEIGHT = 64;
export const NAVBAR_HEIGHT_MOBILE = 49;

export const PAGE_HEADER_OVERLAPS = {
  overlap: 80,
  no_overlap: undefined,
};

const HeaderMedia: FC<
  BoxProps & {
    is_centered: boolean;
    hide_media_on_mobile: boolean;
    media: {
      document:
        | (Gatsby.ElementImageGroupFragment & { __typename: string })
        | (Gatsby.ElementImageConstrainedFragment & { __typename: string })
        | (Gatsby.ElementAnimationFragment & { __typename: string });
    };
    media_video?: string;
  }
> = ({ media, media_video, is_centered, hide_media_on_mobile, ...rest }) => {
  const isAnimatedCarousel = Boolean(
    media?.document?.__typename === "PrismicElementImageGroup",
  );

  return (
    <Box
      position="relative"
      overflow="hidden"
      alignSelf="center"
      bg={{ base: "yellow-0", md: "transparent" }}
      borderRadius="lg"
      height={{
        base: "250px",
        xs: "300px",
        md: is_centered ? "660px" : "670px",
      }}
      marginX={is_centered ? "auto" : undefined}
      marginTop={{
        md: !is_centered ? "-40px" : "auto",
      }}
      marginBottom={{
        md: !is_centered ? "-40px" : "auto",
      }}
      w="full"
      sx={{
        "> *": { marginX: "auto" },
        ".chakra-aspect-ratio ": {
          height: "inherit",
        },
        img: {
          objectFit: {
            md: !isAnimatedCarousel ? "contain !important" : "cover", //important is required to override gatsby plugin image default style,
          },
          borderRadius: "lg",
        },
        "@media print": {
          display: "none",
        },
      }}
      display={{
        base: hide_media_on_mobile ? "none" : "block",
        md: "block",
      }}
      maxW="582px"
      paddingBottom={{ md: is_centered ? "space-80" : undefined }}
      {...rest}
    >
      {media?.document?.__typename === "PrismicElementImage" && (
        <Box
          as={GatsbyImage}
          image={media.document.data?.image_file?.gatsbyImageData}
          alt={media.document.data?.image_file?.alt ?? ""}
          maxWidth={is_centered ? "600px" : "unset"}
          w="full"
          h="full"
          mx={is_centered ? "auto" : undefined}
        />
      )}

      {media?.document?.__typename === "PrismicElementImageGroup" && (
        <HeaderAnimatedImages
          data={media.document.data}
          maxWidth={is_centered ? "600px" : "unset"}
        />
      )}

      {media?.document?.__typename === "PrismicElementAnimation" && (
        <ElementAnimation data={media.document.data} />
      )}

      {media_video && <HeaderVideo youtubeId={media_video} />}
    </Box>
  );
};

export default HeaderMedia;
