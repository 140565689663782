import { isPersistentParam } from "src/lib/queryParams";
import { VisitorContextType } from "src/providers/VisitorContext/types";

const REFERRAL_PARAM = "referral";
const AFFILIATE_PARAM = "affiliate";

const NEW_SIGNUP_URL = ["staging", "development"].includes(
  process.env.GATSBY_DEPLOY_ENV ?? "",
)
  ? "https://app.staging.shine.fr/register"
  : "https://app.shine.fr/register";

const handleWebAppRedirection = (visitorContext: VisitorContextType) => {
  const { affiliateLandingHref, referrerCode, search } = visitorContext;

  const url = new URL(NEW_SIGNUP_URL);

  if (referrerCode) {
    url.searchParams.set(REFERRAL_PARAM, referrerCode);
  }

  if (affiliateLandingHref) {
    url.searchParams.set(AFFILIATE_PARAM, affiliateLandingHref);
  }

  if (search) {
    const params = new URLSearchParams(search);
    for (const [key, val] of params.entries()) {
      if (!url.searchParams.has(key) && isPersistentParam(key)) {
        url.searchParams.set(key, val);
      }
    }
  }

  return url.toString();
};

export default handleWebAppRedirection;
