export const trackExperiment = (experiment: { id: string; name: string }) => {
  const variationId = window.google_optimize.get(experiment.id);

  if (variationId) {
    window.analytics.track("Experiment Viewed", {
      experimentId: experiment.id,
      experimentName: experiment.name,
      variationId,
      // Needed so Google Analytics doesn’t count this event as an user interaction for bounce rate
      // See https://segment.com/docs/connections/destinations/catalog/google-analytics/#non-interaction-events
      nonInteraction: 1,
    });
  }
};
