import { Box, BoxProps } from "@chakra-ui/react";
import { FC, useState, useEffect } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { MotionBox } from "src/atoms";
import { getNextIndex, getPreviousIndex } from "./utils";

export const DELAY = 8000;

const HeaderAnimatedImages: FC<Gatsby.ElementImageGroupFragment & BoxProps> = ({
  data,
  ...rest
}) => {
  const [active, setActive] = useState(0);

  const slides =
    data?.images?.filter((media) => media?.image_file?.document) || [];

  let indexCount = 1; //Start from 1, so when the interval begins, second slide will become next slide

  const cyclePoints = () => {
    indexCount = indexCount < slides.length ? indexCount : 0;
    setActive(indexCount);
    indexCount++;
  };

  useEffect(() => {
    const interval = setInterval(cyclePoints, DELAY);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box position="relative" overflow="hidden" w="full" h="full" {...rest}>
      <Box
        position="absolute"
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
      >
        <Box
          w="full"
          h="full"
          position="relative"
        >
          {slides?.map((media, index) => {
            if (!media) return null;

            const isActiveIndex = active === index;

            return (
              <MotionBox
                key={media?.image_file?.id || ""}
                position="absolute"
                display="flex"
                justifyContent="center"
                alignItems="center"
                w="full"
                h="full"
                top="0"
                left="0"
                opacity="0"
                borderRadius="lg"
                overflow="hidden"
                animate={{
                  opacity: isActiveIndex ? 1 : 0,
                  transform:
                    isActiveIndex ? "translateY(0%)" : "translateY(100%)",
                }}
                transition="opacity 1.5s ease 0s, transform 8s ease 0s, -webkit-transform 8s ease 0s"
              >
                {getPreviousIndex(active, slides.length) === index || isActiveIndex || getNextIndex(active, slides.length) === index ? <Box
                  as={GatsbyImage}
                  h="full"
                  image={
                    media?.image_file?.document?.data?.image_file
                      ?.gatsbyImageData
                  }
                  alt={media?.image_file?.document?.data?.image_file?.alt ?? ""}
                  maxWidth="600px"
                  w="full"
                /> : null}
              </MotionBox>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default HeaderAnimatedImages;
