/**
 * Google Optimize anti flickering snippet
 * To be injected on top of <Head>
 *
 * Original snippet
 *
 *  <!-- anti-flicker snippet (recommended)  -->
 *  <style>.async-hide { opacity: 0 !important} </style>
 *  <script>(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
 *  h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
 *  (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
 *  })(window,document.documentElement,'async-hide','dataLayer',4000,
 *  {'CONTAINER_ID':true});</script>
 *
 * @see https://segment.com/docs/connections/destinations/catalog/google-analytics/#optimize
 * @see https://support.google.com/optimize/answer/7100284
 */


import React, { FC } from 'react';

import { Helmet } from "react-helmet";

const {
  GATSBY_GOOGLE_OPTIMIZE_ID
} = process.env

const AntiFlicker: FC = () => {
  return (
    <Helmet>
      <style type="text/css">
      {`
        .async-hide { opacity: 0 !important} </style>
      `}
      </style>
      <script>
      {`
        (function(a,s,y,n,c,h,i,d,e){
          s.className+=' '+y;
          h.start=1*new Date;
          h.end=i=function(){
            s.className=s.className.replace(RegExp(' ?'+y),'')
          };
          (a[n]=a[n]||[]).hide=h;setTimeout(function(){
            i();
            h.end=null
          },c);
          h.timeout=c;
        })(
          window,document.documentElement,
          'async-hide',
          'dataLayer',
          2000,
          { '${GATSBY_GOOGLE_OPTIMIZE_ID}':true }
        );
      `}
      </script>
    </Helmet>
  )
}

export default AntiFlicker